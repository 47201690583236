import Vue from 'vue';

import Api from '../api';

// Components
import Cart from './cart.vue';
import CartButton from './cart_button.vue';
import LineItemForm from './line_item_form.vue';
import LineItem from './line_item.vue';
import OrderForm from './order_form.vue';
import ProductGridFilter from './catalog/product_grid_filter.vue';

import ProductFilter from './catalog/product_filter.vue';
import ProductFilterButton from './catalog/product_filter_button.vue';

import normalizeParams from '../utilities/clean-object';

import SidemenuMenu from "./sidemenu/menu.vue";
import SidemenuButton from "./sidemenu/button.vue";

Vue.component('Cart', Cart);
Vue.component('CartButton', CartButton);
Vue.component('LineItemForm', LineItemForm);
Vue.component('LineItem', LineItem);
Vue.component('OrderForm', OrderForm);
Vue.component('ProductGridFilter', ProductGridFilter);
Vue.component('ProductFilter', ProductFilter);
Vue.component('ProductFilterButton', ProductFilterButton);
Vue.component('SidemenuMenu', SidemenuMenu);
Vue.component('SidemenuButton', SidemenuButton);

Vue.config.ignoredElements = ['oembed']

const shopAppInit = function() {

  return new Vue({
    el: '#app',
    data: () => {
      return {
        cart: {
          lineItems: [],
          error: '',
          price: {
            totalPrice: 0,
            deliveryPrice: 0,
            discount: 0,
          }
        },
        filter: {
          loadedProducts: [],
          totalItems: null,
        },
        loadingState: false,
        order: {
          errors: [],
          deliveryKind: 'courier',
          promocodeId: '',
          city: 'Москва'
        },
        priceLoadingState: false,
        promocodeApplied: false,
        sidemenuToggle: {
          'filter': false,
          'cart': false,
        },
      };
    },

    created () {
      Api.getCart().then((r) => {
        this.cart.lineItems = r.data.lineItems;
        this.cart.price = r.data.price;
      });

      const event = new Event('shopAppMounted');
      document.dispatchEvent(event);
    },

    methods: {
      toggleSidemenu: function(id) {
        document.body.classList.toggle('scroll-y-disabled');
        this.sidemenuToggle[id] = !this.sidemenuToggle[id];
      },

      applyFilters: function(params) {
        this.loadingState = true;
        // clean params and update URL query parameters
        normalizeParams(params);

        Api.applyFilters(params).then((r) => {
          this.filter['loadedProducts'] = r.data;
          this.filter['totalItems'] = r.data.length;
          // hide preloaded grid
          Array.from(document.getElementsByClassName('replacable-grid')).forEach((e) => {
            e.style.display = "none";
          });
          // show new grid
          document.getElementsByClassName('product-grid')


          if (this.sidemenuToggle['filter']) {
           this.toggleSidemenu('filter');
          }
        }).catch((err) => {
            if (typeof(Sentry) != 'undefined') {
              Sentry.captureException(err);
            }
            this.loadingState = false;
        })
        this.loadingState = false;
      },


      destroyLineItem: function(itemId) {
        this.loadingState = true;

        Api.removeLineItem(itemId)
          .then((r) => {
            const removeIndex = this.cart.lineItems.findIndex((item) => { return item.id == itemId; });
            this.cart.lineItems.splice(removeIndex, 1);

            this.cart.price = r.data;
            this.loadingState = false;
          }).catch((err) => {
            if (typeof(Sentry) != 'undefined') {
              Sentry.captureException(err);
            }
            this.loadingState = false;
          });
      },

      addToCart: function(params) {
        this.loadingState = true;

        Api.addToCart(params.productId, params.variantId).then((r) => {
          this.cart.lineItems = [r.data.lineItem, ...this.cart.lineItems];
          this.cart.price = r.data.price;

          this.loadingState = false;
          this.toggleSidemenu('cart');

          try {
            ym(36471355,'reachGoal','add-to-cart');
          } catch (err){
            if (typeof(Sentry) != 'undefined') {
              Sentry.captureException(err);
              Sentry.captureException(this.cart);
            }
          }
        }).catch((err) => {
          if (err.response.status == 422) {
            this.cart.error = err.response.data.error.message;
          } else {
            this.cart.error = 'Что-то пошло не так. Приносим извинения за доставленные неудобства!';
          }
          this.loadingState = false;
        });

      },

      getPrice: function() {
        this.priceLoadingState = true;
        Api.getPrice({
          'delivery_kind': this.order.deliveryKind,
          'promocode': this.order.promocode,
          'city': this.order.city
        }).then((r) => {
          this.cart.price = r.data;
          this.priceLoadingState = false;
          this.promocodeApplied = this.cart.price?.discount != 0;
        });
      },

      createOrder: function(orderParams) {
  this.loadingState = true;

  Api.createOrder(orderParams)
    .then((r) => {
      this.loadingState = false;
      try {
        ym(36471355, 'reachGoal', 'order_complete');
      } catch (err) {
        if (typeof Sentry !== 'undefined') {
          Sentry.captureException(err);
        }
      }
      window.location.href = `/orders/${r.data.order.uuid}?success=true`;
      this.cart.lineItems = [];
    })
    .catch((err) => {
      let newErrors = [];

      if (err.response && err.response.status === 422) {
        // Handle validation errors
        const responseData = err.response.data;

        // Case 1: Errors are in `error.errors`
        if (responseData.error && Array.isArray(responseData.error.errors)) {
          newErrors = responseData.error.errors;
        }
        // Case 2: Errors are in `errors` (flat array)
        else if (Array.isArray(responseData.errors)) {
          newErrors = responseData.errors;
        }
        // Case 3: Errors are in `errors` (object with field-specific errors)
        else if (responseData.errors && typeof responseData.errors === 'object') {
          newErrors = Object.values(responseData.errors).flat();
        }
        // Case 4: Fallback for unexpected error format
        else {
          newErrors = ['Произошла ошибка при обработке вашего запроса.'];
        }
      } else {
        // Handle other errors (e.g., network errors, server errors)
        newErrors = [
          'При создании заказа что-то пошло не так.',
          'Пожалуйста, попробуйте еще раз или позвоните по любому номеру.',
        ];
      }

      this.loadingState = false;
      this.order.errors = newErrors;
    });
},
    }
  });
};

export default shopAppInit;
